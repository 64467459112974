import * as React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import GlobalStyles from '../styles/globalStyles';
import { Helmet } from 'react-helmet';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlhref } from '../utils';

const Header = styled.h1`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #00483b;
  font-size: ${(props) => props.$fontSize ?? '45px'};
  line-height: ${(props) => props.$lineHeight ?? ' 50px'};
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  @media (max-width: 767px) {
    font-size: ${(props) => props.$fontSize ?? '35px'};
    line-height: ${(props) => props.$lineHeight ?? ' 40px'};
  }
`;

const TopBar = styled.div`
  background: #083a81;
  padding-bottom: 20px;
`;

const TopTagLine = styled.p`
  padding: 11px 0 20px 18px;
  text-transform: uppercase;
  font-family: 'Gotham-Bold', arial, sans-serif;
  color: #0397d6;
  float: none;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  @media (max-width: 450px) {
    font-size: 9px;
    line-height: 9px;
  }
`;

const LogoStyle = styled.img`
  margin: auto;
  display: flex;
  padding: 20px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const LinkStyle = styled.a`
  text-decoration: none !important;
  color: #083a81;
  text-align: left;
  :hover {
    text-decoration: none;
    color: #1fb25a;
    outline: none;
  }
`;

const NotFoundPage = ({ data, location }) => {
  const topHeaderStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TopTagLine>{children}</TopTagLine>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
          href={
            isExternalUrlhref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TopTagLine style={{ textAlign: 'left' }}>{children}</TopTagLine>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
          href={
            isExternalUrlhref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const pageData = data.allContentfulChevron.edges[0].node.sections;

  const topBarData = pageData.filter((section) => {
    return section.type === '404PageHeader';
  })[0];
  const pageContent = pageData.filter((section) => {
    return section.type === '404PageContent';
  })[0];

  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>
          {data?.allContentfulChevron?.edges[0]?.node?.title ??
            'Parkland | Chevron :: Page Not Found'}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <TopBar>
        <LogoStyle src={topBarData?.image?.file?.url}></LogoStyle>
        {topBarData?.richText?.raw &&
          documentToReactComponents(
            JSON.parse(topBarData?.richText?.raw),
            topHeaderStyle
          )}
      </TopBar>
      <Container className="pt-3">
        <Header>{pageContent?.header}</Header>
        <LinkStyle>
          {pageContent?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(pageContent?.contentDetails?.raw),
              optionsMainStyle
            )}
        </LinkStyle>
      </Container>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query pageNotFoundQuery {
    allContentfulChevron(
      filter: {
        identifier: { type: { eq: "pageNotFound" } }
        node_locale: { eq: "en-US" }
      }
    ) {
      edges {
        node {
          slug
          name
          node_locale
          identifier {
            type
          }
          title
          metadata
          sections {
            ... on ContentfulImageWithPath {
              id
              title
              type
              image {
                file {
                  url
                  fileName
                }
              }
              path
              richText {
                raw
              }
            }
            ... on ContentfulContent {
              id
              title
              type
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
